import axios from "axios";
import React, { useEffect, useState} from "react";
import { OverallHomework } from "src/interface";
import { useParams, Link } from "react-router-dom";
import { authHeader } from "src/utils/authHeader";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

export const AnalitycsPage3 = () => {
  document.title = "Аналитика ошибок | EasyENT";
  const params = useParams();
  const [janlessonsAnalytics, setJanLessonsAnalytics] = useState<OverallHomework []>([]);
  const [janOverallHomework, setJanOverallHomework] = useState<OverallHomework[]>([]);
  useEffect(() => {
    axios
      .get("/api/v1/homework/course/" + params.id + "/lessons_homeworks?start_date=2025-01-20&end_date=2025-02-20", { headers: authHeader() })
      .then((response) => {
        setJanLessonsAnalytics(response.data);
    });
    axios
      .get("/api/v1/homework/overall_lessons_homeworks?start_date=2025-01-20&end_date=2025-02-20&course_id=" + params.id, {
        headers: authHeader(),
      })
      .then((response) => {
        setJanOverallHomework(response.data);
      });
  }, []);
  const [activeTab, setActiveTab] = useState('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    console.log("Tab changed to: ", newValue); // Check if the function is triggered
    setActiveTab(newValue);
  };
  return (
    <>
      <div className="box">
        <div className="analize-top">
          <h1 className="analize-top__heading">Аналитика ошибок</h1>
        </div>
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Январь" value="1" />
            </TabList>
          </Box>
          <TabPanel className="analytics-panel" value="1">
            <span className="analytics-panel__heading">Общии процент решение дз: {janOverallHomework.length > 0 && janOverallHomework[0].homework_is_correct_percent ? 
              `${janOverallHomework[0].homework_is_correct_percent.toFixed(0)}%` 
            : "0%"
            } </span>
            <ul className="anal-ul">
            {janlessonsAnalytics.length ?
              janlessonsAnalytics.map((lesson, index) =>
                (
                  <li key={index}>
                    <Link to={`lesson_${lesson.lesson_id}`} className="lesson-analize">
                      <span className="lesson-analize__heading">
                        {lesson.name}
                      </span>
                      <div className="lesson-analize__row">
                        <p>Процент дз: {lesson.homework_is_correct_percent} %</p>
                      </div>
                      <div className="lesson-analize__row">
                        <p>Правильно: {lesson.homework_is_correct_count} из {lesson.question_count}</p>
                      </div>
                      <span className="btn btn-primary">Смотреть решения</span>
                    </Link>
                  </li>
                )) : ""
            }
            </ul>
          </TabPanel>
        </TabContext>
      </div>
    </>
  );
};
