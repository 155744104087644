import axios from "axios";
import { authHeader } from "src/utils/authHeader";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ICourse } from "src/interface";
import "./MyCoursesPage.scss";

export const MyCourses = () => {
  document.title = "Мои Курсы | EasyENT";
  const [allCourses, setAllCourses] = useState<ICourse[]>([]);
  const [coursesType, setCourseType] = useState<boolean>(true);
  useEffect(() => {
    axios
      .get("/api/v1/courses/my/?skip=0&limit=100&active=" + coursesType,{ headers: authHeader()})
      .then((response) => setAllCourses(response.data));
  }, [coursesType]);
 
  const findExistCourse = allCourses;

  return (
    <div className="my-course">
      <h1 className="title">Мои курсы</h1>
      <ul className="mycourse-list">
        <li>
          <div className={coursesType == true ? "course-link course-link--active" : "course-link"} onClick={
            () => setCourseType(true)
          }>Курсы 2025</div>
        </li>
        <li>
          <div className={coursesType == false ? "course-link course-link--active" : "course-link"} onClick={
            () => setCourseType(false)
          }>Старые</div>
        </li>
      </ul>
      <ul className="course-spisok">
        {findExistCourse.map((course) => (
          <li key={course.id}>
            <div className="course-item course-item--inner box">
             {course.price == 10 ? 
                <Link to={`/course_${course.id}`} className="image-wrapper">
                  <img src={course.photo_url} />
                </Link> : 
                course.price == 20 ? 
                <Link to={`/course2_${course.id}`} className="image-wrapper">
                  <img src={course.photo_url} />
                </Link>
                : <Link to={`/courseOld_${course.id}`} className="image-wrapper">
                <img src={course.photo_url} />
              </Link>
              }
              
              <div className="course-wrapper">
                <span className="course-wrapper__title">{course.name}</span>
                <div className="course-details">
                  <div>
                    <span>
                      <i className="fa-solid fa-user"></i>
                    </span>
                    {course.teacher ? course.teacher.name : ""}
                  </div>
                </div>
                {
                  course.price == 10 ? 
                  <Link to={`/course_${course.id}`} className="btn btn-primary btn-flex">
                    Подробнее о курсе <i className="fa-solid fa-angle-right"></i>
                  </Link> : 
                  course.price == 20 ? 
                  <Link to={`/course2_${course.id}`} className="btn btn-primary btn-flex">
                    Подробнее о курсе<i className="fa-solid fa-angle-right"></i>
                  </Link> :
                  course.price == 30 ? 
                   <Link to={`/course3_${course.id}`} className="btn btn-primary btn-flex">
                    Подробнее о курсе<i className="fa-solid fa-angle-right"></i>
                  </Link> :
                   <Link to={`/courseOld_${course.id}`} className="btn btn-primary btn-flex">
                    Подробнее о курсе<i className="fa-solid fa-angle-right"></i>
                  </Link> 
                }
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
