import React, { useState, FC, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import "./Navbar.scss";
import { ReactComponent as PeopleIcon } from "../../svg/people.svg";
import { ReactComponent as PersonIcon } from "../../svg/person.svg";
import { ReactComponent as TableBottomRow } from "../../svg/table-bottom-row.svg";
import { ReactComponent as TestIcon } from "../../svg/test.svg";
import { ReactComponent as Power } from "../../svg/power.svg";
import { ReactComponent as Money } from "../../svg/shopping-bag.svg";
// import { ReactComponent as FriendIcon } from "../../svg/tour-money.svg";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { useNavigate } from "react-router-dom";
import { UserSlice } from "src/service/reducers/UserSlice";

export const Navbar: FC<{ stateMobShow: boolean }> = (stateMobShow) => {
  const { signOutUser } = UserSlice.actions;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [mobshow, setmobShow] = useState<boolean>(stateMobShow.stateMobShow);
  useEffect(() => {
    setmobShow(stateMobShow.stateMobShow);
  }, [stateMobShow]);
  const user = useAppSelector((store: any) => store.user.data);
  const users = {
    fullName: "",
    phone: "",
    postalCode: "",
    isStudent: true,
    personData: false,
    mailAdvertising: false,
    publicOffers: false,
  };

  const signOut = function () {
    dispatch(signOutUser(users));
    navigate("/login");
  };

  return (
    <div className={mobshow == true ? "navbar navbar--active" : "navbar"}>
      <div className="navbar__area">
        <Link to="/my-courses" className="logo">
          <img src="/assets/svg/EasyEnt.svg"/>
        </Link>
        <nav>
          {!user?.is_parent && (
            <NavLink
              className={({ isActive }) => (isActive ? "active" : undefined)}
              to="/my-courses"
            >
              <TableBottomRow />
              <p>Мои курсы</p>
            </NavLink>
          )}
          <NavLink
            className={({ isActive }) => (isActive ? "active" : undefined)}
            to="/teachers"
          >
            <PeopleIcon />
            <p>Преподаватели</p>
          </NavLink>
          <NavLink
            className={({ isActive }) => (isActive ? "active" : undefined)}
            to="/my-profile"
          >
            <PersonIcon />
            <p>Мой профиль</p>
          </NavLink>
          <NavLink
            className={({ isActive }) => (isActive ? "active" : undefined)}
            to="/subscriptions"
          >
            <Money />
            <p>Мои оплаты</p>
          </NavLink>
          {!user?.is_parent && (
            <NavLink
              className={({ isActive }) => (isActive ? "active" : undefined)}
              to="/tests"
            >
              <TestIcon />
              <p>Тесты ЕНТ</p>
            </NavLink>
          )}
          <span className="logout" onClick={signOut.bind(this)}>
            <Power />
            <p>Выход</p>
          </span>
        </nav>
      </div>
    </div>
  );
};
