import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "src/utils/axios";
import { authHeader } from "src/utils/authHeader";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import toast from "src/utils/toast";
import { useSkipInitialEffect } from "src/utils/hooks";
import { Preloader } from "src/components";
import "./TestsEntPage.scss";

interface IProcess {
  start_time: string | Date;
  is_full: boolean;
  user_id: number;
  create_time: string;
  end_time: string;
  id: number;
  user: any;
  result: any;
}

export const TestsEntPage = () => {
  document.title = "Тесты | EasyENT";
  const navigate = useNavigate();
  const location: any = useLocation();
  const [loading, setLoading] = useState(false);
  const [activeProcess, setActiveProcess] = useState<IProcess | any>({});
  const [subjects, setSubjects] = useState<any>();
  const [questions, setQuestions] = useState<any>();
  const [answers, setAnswers] = useState<any>({});
  const [rightAnswers, setRightAnswers] = useState<any>({});
  const [isFinished, setIsFinished] = useState<boolean>(false);
  // let prevCount: number[] = [];

  function getActiveProcess() {
    setLoading(true);
    const url = (location?.state?.processId) ? `/api/v1/tests/process/${location?.state?.processId}/` : `/api/v1/tests/process/active`;
    axios
      .get(url, {
        headers: authHeader(),
      })
      .then((res: any) => {
        if (res?.data?.id) {
          if (location?.state?.processId) {
            setIsFinished(true);
            getResult(location?.state?.processId);
          }
          setActiveProcess(res?.data);
          setSubjects(res?.data?.tests);
          getQuestions(res?.data?.tests?.[0]?.id);

          const time = res?.data?.tests?.reduce(
            (acc: number, cur: any) =>
              cur?.subject?.type === "standart" ? acc + 36000000 : acc + 24000000,
            0
          );
          const currAndStartTimeDiff =
            +new Date() -
            +new Date(moment.utc(res?.data?.start_time).local().toString());

          if (currAndStartTimeDiff > time && !location?.state?.processId) {
            submitTest("Время вышло, тест завершен", "warning");
            navigate("/tests");
          }
        } else {
          navigate("/tests");
        }
      })
      .finally(() => setLoading(false));
  }

  function getQuestions(id: number) {
    if (!questions?.[id]) {
      axios
        .get(`/api/v1/tests/tests/${id}`, { headers: authHeader() })
        .then((res: any) => {
          setQuestions({ ...questions, [res?.data?.id]: res?.data?.questions });
        });
    }
  }

  function submitTest(
    msg?: string,
    type?: "info" | "success" | "warning" | "error"
  ) {
    axios
      .post(
        "/api/v1/tests/process/finish",
        {
          answers: answers,
        },
        { headers: authHeader() }
      )
      .then(() => {
        getResult(activeProcess?.id);
        localStorage.removeItem("answers");
        toast(msg || "Тест выполнен", type || "success");
        setIsFinished(true);
      });
  }

  function getResult(id: number) {
    setLoading(true);
    axios
      .get(`/api/v1/tests/process/${id}/`, {
        headers: authHeader(),
      })
      .then((res: any) => {
        setRightAnswers(res?.data);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (localStorage.getItem("answers")) {
      setAnswers(JSON.parse(localStorage.getItem("answers") || ""));
    }
    getActiveProcess();
  }, []);

  useSkipInitialEffect(() => {
    localStorage.setItem("answers", JSON.stringify(answers));
  }, [answers]);

  return (
    <div className="tests-ent-page">
      <ul className="breadcrumbs">
        <li>
          <Link to="/tests">Тесты</Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <span>Пробный тест .</span>
        </li>
      </ul>
      <div className="header-container">
        <h1 className="title">Пробный тест</h1>
        {loading ? (
          <Preloader />
        ) : isFinished && rightAnswers?.result ? (
          <h1 className="title">
            {`Общий балл: ${Object.values(rightAnswers?.result)?.reduce(
              (acc: number, cur: any) => acc + cur,
              0
            )}`}
          </h1>
        ) : activeProcess?.id && !isFinished ? (
          <>
            <button className="btn btn-primary" onClick={() => submitTest()}>
              Завершить тест
            </button>
          </>
        ) : (
          ""
        )}
      </div>
      {loading ? (
        <Preloader />
      ) : (
        <Tabs className="tabs">
          <TabList>
            {subjects?.map((subject: any) => (
              <Tab key={subject?.id} onClick={() => getQuestions(subject?.id)}>
                {subject?.subject?.title}
              </Tab>
            ))}
          </TabList>
          {subjects?.map((subject: any) => (
            <TabPanel key={subject?.id}>
              {questions?.[subject?.id] &&
                questions?.[subject?.id].map((question: any, index: number) => (
                  <div className="question" key={question?.question.id}>
                    {question.question.question_type == "choice" ? (
                      <>
                        <div className="question__heading" key={question?.question?.id + index}>
                        <span>{`${index + 1}. ${question?.question?.display_name}`}</span>
                        {question.question.image !== null ? (
                          <div className="question__img">
                            <img src={question.question.image}></img>
                          </div>
                        ) : (
                          ""
                        )}
                        </div>
                        {question?.question?.variants?.map((variant: any) => (
                        <div key={variant?.id} className="question-variant">
                          <div className="question-variant__left">
                            <input
                              type="checkbox"
                              name={variant?.id}
                              checked={
                                location?.state?.processId
                                  ? !!rightAnswers?.answers?.find(
                                      (ra: any) => ra?.variant_id === variant.id
                                    )
                                  : answers?.[question?.question?.id]?.includes(
                                      variant?.id
                                    )
                              }
                              id={variant?.id}
                              onChange={(e: any) => {
                                const indexAnswer = Array.isArray(answers)
                                  ? answers?.[question?.question.id]?.indexOf(
                                      variant?.id
                                    )
                                  : -1;
                                if (indexAnswer === -1 && e.target.checked) {
                                  const selected = answers?.[
                                    question?.question.id
                                  ]
                                    ? // eslint-disable-next-line no-unsafe-optional-chaining
                                      [...answers?.[question?.question.id]]
                                    : [];
                                  selected.push(variant?.id);
                                  setAnswers({
                                    ...answers,
                                    [question?.question?.id]: selected,
                                  });
                                } else {
                                  const filtered = [
                                    // eslint-disable-next-line no-unsafe-optional-chaining
                                    ...answers?.[question?.question?.id],
                                  ];
                                  setAnswers({
                                    ...answers,
                                    [question?.question?.id]: filtered?.filter(
                                      (item: number) => item !== variant?.id
                                    ),
                                  });
                                }
                              }}
                              disabled={
                                isFinished ||
                                (question?.question?.variants?.length < 6
                                  ? answers?.[question?.question?.id]?.length ===
                                      1 &&
                                    !answers?.[question?.question?.id]?.includes(
                                      variant?.id
                                    )
                                  : answers?.[question?.question?.id]?.length >=
                                      2 &&
                                    !answers?.[question?.question?.id]?.includes(
                                      variant?.id
                                    ))
                              }
                            />
                          </div>
                          <div className={ isFinished &&
                                  rightAnswers?.answers?.find(
                                    (ra: any) =>
                                      ra?.question?.id ===
                                        question?.question?.id &&
                                      ra?.question?.variants?.find(
                                        (v: any) => v?.id === variant.id
                                      )?.is_correct === true
                                  ) ? "question-variant__right green"
                                    : isFinished &&
                                      rightAnswers?.answers?.find(
                                        (ra: any) =>
                                          ra?.question?.id ===
                                            question?.question?.id &&
                                          ra?.question?.variants?.find(
                                            (v: any) => v?.id === variant.id
                                          )?.is_correct === false
                                      )
                                    ? "question-variant__right"
                                    : "question-variant__right"
                              }>
                            {variant.image !== null ? (
                                    <label htmlFor={"" + variant.id}>
                                      <div className="admin-choice-row__img">
                                        <img src={variant.image}></img>
                                      </div>
                                    </label>
                                  ) : (
                                    <label
                                    htmlFor={variant?.id}>
                                    {variant?.value}
                                  </label>
                            )}
                          </div>
                        </div>
                        ))}
                      </>
                      ) : (
                        <>
                          <div className="question__heading" key={question?.question?.id + index}>
                            <span>{`${index + 1}. ${question?.question?.display_name}`}</span>
                            {question.question.image !== null ? (
                              <div className="question__img">
                                <img src={question.question.image}></img>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <table className="group-table">
                            {question["question"]["variants"].length > 7 ? (
                              <>
                                <tr>
                                  <td>
                                    {question["question"]["variants"].filter((item:any) => item.name == "value1").map((choice: any) => (
                                      <p key={choice.group_name}>{choice.group_name}</p>
                                    ))
                                    }
                                  </td>
                                  <td>
                                    <ul className="group-ul">
                                      {
                                        question["question"]["variants"]
                                        .sort((a: any, b: any) => a?.name.substring(5, 6) - b?.name.substring(5, 6))
                                        .slice(0, 4)
                                        .map((variant: any) => (
                                          <li key={variant.id} >
                                            <div key={variant?.id} className="question-variant">
                                              <div className="question-variant__left">
                                                <input
                                                  type="checkbox"
                                                  name={variant?.id}
                                                  checked={
                                                    location?.state?.processId
                                                      ? !!rightAnswers?.answers?.find(
                                                          (ra: any) => ra?.variant_id === variant.id
                                                        )
                                                      : answers?.[question?.question?.id]?.includes(
                                                          variant?.id
                                                        )
                                                  }
                                                  id={variant?.id}
                                                  onChange={(e: any) => {
                                                    const indexAnswer = Array.isArray(answers)
                                                      ? answers?.[question?.question.id]?.indexOf(
                                                          variant?.id
                                                        )
                                                      : -1;
                                                    if (indexAnswer === -1 && e.target.checked) {
                                                      const selected = answers?.[
                                                        question?.question.id
                                                      ]
                                                        ? // eslint-disable-next-line no-unsafe-optional-chaining
                                                          [...answers?.[question?.question.id]]
                                                        : [];
                                                      selected.push(variant?.id);
                                                      setAnswers({
                                                        ...answers,
                                                        [question?.question?.id]: selected,
                                                      });
                                                    } else {
                                                      const filtered = [
                                                        // eslint-disable-next-line no-unsafe-optional-chaining
                                                        ...answers?.[question?.question?.id],
                                                      ];
                                                      setAnswers({
                                                        ...answers,
                                                        [question?.question?.id]: filtered?.filter(
                                                          (item: number) => item !== variant?.id
                                                        ),
                                                      });
                                                    }
                                                  }}
                                                  disabled={
                                                    isFinished ||
                                                    (question?.question?.variants?.length < 6
                                                      ? answers?.[question?.question?.id]?.length ===
                                                          1 &&
                                                        !answers?.[question?.question?.id]?.includes(
                                                          variant?.id
                                                        )
                                                      : answers?.[question?.question?.id]?.length >=
                                                          2 &&
                                                        !answers?.[question?.question?.id]?.includes(
                                                          variant?.id
                                                        ))
                                                  }
                                                />
                                              </div>
                                              <div className={ isFinished &&
                                                      rightAnswers?.answers?.find(
                                                        (ra: any) =>
                                                          ra?.question?.id ===
                                                            question?.question?.id &&
                                                          ra?.question?.variants?.find(
                                                            (v: any) => v?.id === variant.id
                                                          )?.is_correct === true
                                                      ) ? "question-variant__right green"
                                                        : isFinished &&
                                                          rightAnswers?.answers?.find(
                                                            (ra: any) =>
                                                              ra?.question?.id ===
                                                                question?.question?.id &&
                                                              ra?.question?.variants?.find(
                                                                (v: any) => v?.id === variant.id
                                                              )?.is_correct === false
                                                          )
                                                        ? "question-variant__right"
                                                        : "question-variant__right"
                                                  }>
                                                {variant.image !== null ? (
                                                        <label htmlFor={"" + variant.id}>
                                                          <div className="admin-choice-row__img">
                                                            <img src={variant.image}></img>
                                                          </div>
                                                        </label>
                                                      ) : (
                                                        <label
                                                        htmlFor={variant?.id}>
                                                        {variant?.value}
                                                      </label>
                                                )}
                                              </div>
                                            </div>
                                          </li>
                                        ))  
                                      }  
                                    </ul>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {question["question"]["variants"].filter((item:any) => item.name == "value8").map((choice: any) => (
                                      <p key={choice.group_name}>{choice.group_name}</p>
                                    ))
                                    }
                                  </td>
                                  <td>
                                    <ul className="group-ul">
                                      {
                                        question["question"]["variants"]
                                        .sort((a: any, b: any) => a?.name.substring(5, 6) - b?.name.substring(5, 6))
                                        .slice(4, 8)
                                        .map((variant: any) => (
                                          <li key={variant.id} >
                                            <div key={variant?.id} className="question-variant">
                                              <div className="question-variant__left">
                                                <input
                                                  type="checkbox"
                                                  name={variant?.id}
                                                  checked={
                                                    location?.state?.processId
                                                      ? !!rightAnswers?.answers?.find(
                                                          (ra: any) => ra?.variant_id === variant.id
                                                        )
                                                      : answers?.[question?.question?.id]?.includes(
                                                          variant?.id
                                                        )
                                                  }
                                                  id={variant?.id}
                                                  onChange={(e: any) => {
                                                    const indexAnswer = Array.isArray(answers)
                                                      ? answers?.[question?.question.id]?.indexOf(
                                                          variant?.id
                                                        )
                                                      : -1;
                                                    if (indexAnswer === -1 && e.target.checked) {
                                                      const selected = answers?.[
                                                        question?.question.id
                                                      ]
                                                        ? // eslint-disable-next-line no-unsafe-optional-chaining
                                                          [...answers?.[question?.question.id]]
                                                        : [];
                                                      selected.push(variant?.id);
                                                      setAnswers({
                                                        ...answers,
                                                        [question?.question?.id]: selected,
                                                      });
                                                    } else {
                                                      const filtered = [
                                                        // eslint-disable-next-line no-unsafe-optional-chaining
                                                        ...answers?.[question?.question?.id],
                                                      ];
                                                      setAnswers({
                                                        ...answers,
                                                        [question?.question?.id]: filtered?.filter(
                                                          (item: number) => item !== variant?.id
                                                        ),
                                                      });
                                                    }
                                                  }}
                                                  disabled={
                                                    isFinished ||
                                                    (question?.question?.variants?.length < 6
                                                      ? answers?.[question?.question?.id]?.length ===
                                                          1 &&
                                                        !answers?.[question?.question?.id]?.includes(
                                                          variant?.id
                                                        )
                                                      : answers?.[question?.question?.id]?.length >=
                                                          2 &&
                                                        !answers?.[question?.question?.id]?.includes(
                                                          variant?.id
                                                        ))
                                                  }
                                                />
                                              </div>
                                              <div className={ isFinished &&
                                                      rightAnswers?.answers?.find(
                                                        (ra: any) =>
                                                          ra?.question?.id ===
                                                            question?.question?.id &&
                                                          ra?.question?.variants?.find(
                                                            (v: any) => v?.id === variant.id
                                                          )?.is_correct === true
                                                      ) ? "question-variant__right green"
                                                        : isFinished &&
                                                          rightAnswers?.answers?.find(
                                                            (ra: any) =>
                                                              ra?.question?.id ===
                                                                question?.question?.id &&
                                                              ra?.question?.variants?.find(
                                                                (v: any) => v?.id === variant.id
                                                              )?.is_correct === false
                                                          )
                                                        ? "question-variant__right"
                                                        : "question-variant__right"
                                                  }>
                                                {variant.image !== null ? (
                                                        <label htmlFor={"" + variant.id}>
                                                          <div className="admin-choice-row__img">
                                                            <img src={variant.image}></img>
                                                          </div>
                                                        </label>
                                                      ) : (
                                                        <label
                                                        htmlFor={variant?.id}>
                                                        {variant?.value}
                                                      </label>
                                                )}
                                              </div>
                                            </div>
                                          </li>
                                        ))  
                                      }  
                                    </ul>
                                  </td>
                                </tr>
                              </>
                            ) : (
                              "Ошибка"
                            )}
                            
                          </table>
                        </>
                        
                      )               
                    }  
                  </div>
                ))}
            </TabPanel>
          ))}
        </Tabs>
      )}
    </div>
  );
};
