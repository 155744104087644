import React, { FC } from "react";
import { Link } from "react-router-dom";
import { ILesson } from "src/interface";
import "./MyLessonItem.scss";
import moment from "moment";
import "moment/locale/ru";
moment.locale("ru");
import { useTranslation } from "react-i18next";
import { namespaces } from "src/i18n/i18n.constants";
export const MyLessonItem: FC<{
  lesson: ILesson;
  index: number;
  completed: any;
  without_penalty: any;
}> = ({ lesson, completed, without_penalty }) => {
  const getLessonDay = (day: Date) => {
    const lessonDay = moment(day).format("ll");
    return lessonDay;
  };
  const today = moment();
  const { t,  } = useTranslation(namespaces.pages.hello);
  function getDeadLineText(lesson: ILesson) {
    if (lesson?.end_date) {
      const endDate = moment(lesson.end_date);
      const deadLine = moment(endDate).add(3, "days");
      const todaysDate = moment();
      const daysDifference = deadLine.diff(todaysDate, "days");
      if (daysDifference < 0) {
        return ``;
      } else if (daysDifference === 0) {
        return "Сегодня последний день";
      } else if (daysDifference > 0 && daysDifference < 4) {
        return `Осталось ${Math.abs(daysDifference)} дн.`;
      } else {
        return ``;
      }
    }
  }

  return (
    <>
      <div className="lesson-card">
          {lesson.lesson_type == "control" ? (
            <Link to={`lesson_video_${lesson.id}`} className="lesson-card__heading">{lesson.name}</Link>
          ) : (
            <Link to={`lesson_video_${lesson.id}`} className="lesson-card__heading">
              {lesson.name}
            </Link>
          )}
          <span className="lesson-id">{lesson.id}</span>
          <span className="lesson-card__end-date">
            <span>
              <i className="fa-regular fa-calendar"></i>
            </span> 
            {lesson.start_date ? getLessonDay(lesson.start_date) : "f"}
          </span>
          {
            without_penalty !== true ?
              getDeadLineText(lesson) != "" ? (
                <span className="lesson-card__deadline">
                  <span>
                    <i className="fa-regular fa-clock"></i>
                  </span> 
                  {getDeadLineText(lesson)}
                </span>
              ) : (
                <></>
              ) : ""
          }
          {lesson.lesson_type == "control" ? (
            <Link to={`control_${lesson.id}`} className={
              completed?.find(
                (el: any) =>
                  el?.lesson_id === lesson?.id && el?.count_homework > 0
              )
                ? "btn-work btn-work--full"
                : "btn-work"
            }>
              Контрольная работа 
              {completed?.find(
                (el: any) =>
                  el?.lesson_id === lesson?.id && el?.count_homework > 0
                ) &&  <i className="fa-solid fa-check"></i>
              }
            </Link>
          ) : (
            <div className="lesson-card__buttons">
              {(today > (lesson.start_date ? moment(lesson.start_date) : "f")) ? (
                <>
                  {(lesson.available == true) ? (
                    <>
                      <Link
                        to={`homework_${lesson.id}`}
                        className={
                          completed?.find(
                            (el: any) =>
                              el?.lesson_id === lesson?.id && el?.count_homework > 0
                          )
                            ? "btn-work btn-work--full"
                            : "btn-work"
                        }
                        >
                        ДЗ 
                        {completed?.find(
                          (el: any) =>
                            el?.lesson_id === lesson?.id && el?.count_homework > 0
                          ) &&  <i className="fa-solid fa-check"></i>
                        }
                      </Link>
                      <Link to={`lesson_video_${lesson.id}`} className="btn-work">
                        Запись
                        <i className="fa-solid fa-angle-right"></i>
                      </Link>
                      <a
                          href={lesson.conspected}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn-work"
                        >
                          Тетрадь <i className="fa-solid fa-angle-right"></i>
                      </a>
                    </>
                  ) : (
                    <>
                      <div className="btn-work">
                        {t("Запись недоступно", { ns: namespaces.common })}
                        <i className="fa-solid fa-angle-right"></i>
                      </div>
                      <div className="btn-work">
                        {t("Домашнее задание не доступно", { ns: namespaces.common })}
                        <i className="fa-solid fa-angle-right"></i>
                      </div>
                    </>
                  )}
                </>
                ) : (
                <div>
                  {lesson.conspected ? (
                  <a
                      href={lesson.conspected}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-work"
                    >
                      Рабочая тетрадь <i className="fa-solid fa-angle-right"></i>
                  </a>
                  ) : ""}
                  <div>Запись и дз будет доступно после урока</div>
                </div>
              )}
              
            </div>
          )}
          
      </div>
    </>
  );
};
