import axios from "axios";
import React, { FC, useState, useEffect } from "react";
import {OrderPosition, IMiniCourse} from "src/interface";
import Select from "react-select";
import { authHeader } from "src/utils/authHeader";
import "./ModalCourseAdd.scss";

export const ModalCourseAdd: FC<{
  active: boolean;
  setActive: (value: boolean) => void;
  start_date: string;
  end_date: string;
  positions: OrderPosition[];
  setPositions: React.Dispatch<React.SetStateAction<OrderPosition[]>>; 

}> =
  ({ active, setActive, start_date, end_date, setPositions, positions }) => {
    const [courses, setCourses] = useState<IMiniCourse[]>([]);
    const [filteredCourses, setFilteredCourses] = useState<IMiniCourse[]>([]);  
    const [potok, setPotok] = useState({ value: 1, label: "Поток 1" });
    const potokOptions: any = [
      { value: 1, label: "Поток 1" },
      { value: 2, label: "Поток 2" },
      { value: 3, label: "Поток 3" },
    ];    
    useEffect(() => {
      axios
        .get("/api/v1/courses?lang=ru&active=true", { headers: authHeader() })
        .then((response) => setCourses(response.data));  
    }, []);
    useEffect(() => {
      if (potok.value === 1) {
        setFilteredCourses(courses.filter((course) => course.price === 10));
      } 
      else if(potok.value === 2) {
        setFilteredCourses(courses.filter((course) => course.price === 20)); // Reset to show all courses if potok.value is not 1
      }
      else if(potok.value === 3) {
        setFilteredCourses(courses.filter((course) => course.price === 30)); // Reset to show all courses if potok.value is not 1
      }
    }, [potok, courses]);

    console.log(positions);

    const handleCheckboxChange = (
      course: IMiniCourse,
      checked: boolean
    ): void => {
      if (checked) {
        const newPosition: OrderPosition = {
          course_id: course.id,
          start_date,
          end_date,
        };
        setPositions((prevPositions) => [...prevPositions, newPosition]);
      } else {
        setPositions((prevPositions) =>
          prevPositions.filter((position) => position.course_id !== course.id)
        );
      }
    };
    return (
      <div className={active ? "modal-photo active" : "modal-photo"} onClick={() => setActive(false)}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <div className="modal-content__heading2">
            <h1>Добавить курсы</h1>
          </div>
          <form className="popup-form">
            <div className="edit-form-row">
              <Select
                value={potok}
                options={potokOptions} 
                onChange={(option: any) => {
                  setPotok(option);
                }}
              />
            </div>
            <div className="edit-form-row">
                <label className="edit-form-row__label">Период оплаты:</label>
                <div className="flex">
                  <div className="date-row">
                    <div>{start_date}</div>
                  </div>
                  <div className="date-row">
                    <div>{end_date}</div>
                  </div>
                </div>
              </div>  
            <div className="edit-form-row">
              <label className="edit-form-row__label">Курсы:</label>
              <ul className="edit-courses">
                {filteredCourses.length
                  ? filteredCourses.map((course) => (
                    <li key={course.id}>
                      <label className="radio-label">
                        <input
                          type="checkbox"
                          name="package"
                          value={course.id}
                          checked={positions.some(
                            (position) => position.course_id === course.id
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(course, e.target.checked)
                          }
                        />
                        {course.name}
                      </label>
                    </li>
                  ))
                : ""}
              </ul>
            </div>
          </form>
        </div>
      </div>
    );
  };
