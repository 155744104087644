import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "src/hooks/redux";
import { User_feeds, OrderShort} from "src/interface";
import { authHeader } from "src/utils/authHeader";
import { Tabs } from 'react-simple-tabs-component'
import "./SubscriptionsPage.scss";
export const SubscriptionsPage = () => {
  document.title = "Мои оплаты | EasyENT";
  const [userFeeds, setUserFeeds] = useState<User_feeds[]>([]);
  const user = useAppSelector((store) => store.user.data);
  const [orders, setOrders] = useState<OrderShort[]>([]);
  useEffect(() => {
    if(!user.id){
      console.log('пустo')
    }
    else{
      axios
      .get("/api/v1/order/my",{ headers: authHeader()})
      .then((response) => setOrders(response.data));
      axios
      .get("/api/v1/user_feed/?user_id=" + user.id,{ headers: authHeader()})
      .then((response) => setUserFeeds(response.data));
    }
  }, []);
  
  const TabSt = () => {
    const order = orders.filter((o)=> o.start_date == '2024-09-09').sort((o)=>o.id).reverse()[0];
    return (
      <>      
      {order ? 
      <div className="payment-item box">
          <div className="payment-row">  
            <p className="payment-row__title">Номер заказа</p>
            <p>{order ? order.id : "Загрузка"}</p>
          </div>
          <div className="payment-row">  
            <p className="payment-row__title">Мои курсы</p>
            <ul>
              {order.positions ? order.positions.map((item:any) => 
                <li key={item.order_id}>
                  {item.course?.name}
                </li> 
              ) : ""}
            </ul>
          </div>
          <div className="payment-row">  
            <p className="payment-row__title">Стоимость</p>
            <p>{order ? order.total_amount : "Загрузка"}</p>
          </div>
          <div className="payment-row payment-row--last">    
            <p className="payment-row__title">Статус</p>
              {userFeeds.filter(e => e.start_date == "2024-09-09").length > 0  ?
                <p><i className="fa-solid fa-check"></i>Оплачено</p> : 
                <p><i className="fa-solid fa-xmark"></i>Не оплачено</p>
              }
          </div>
            {order !== undefined && userFeeds.filter(e => e.start_date == "2024-09-09").length < 0 ?  
              <div>
                <form target="_blank" method="post" action="https://kaspi.kz/online" id="formOnlinePayment">
                    <input type="hidden" name="TranId" value={order.id + ""}/>
                    <input type="hidden" name="OrderId" value={order.id + ""}/>
                    <input type="hidden" name="Amount" value={(order.total_amount * 100) + ""}/>
                    <input type="hidden" name="Service" value="StayStudy"/>
                    <input type="hidden" name="ReturnUrl" value={"https://app.easyent.kz/transaction/success?id="+order.id}/>
                    <button className="btn btn-primary btn-flex">Оплатить через Kaspi</button>
                </form> 
                <div className="payment-info">
                  <span className="payment-info__heading2">Если оплата не выходит при нажатии на кнопку оплатить</span>
                  <ul className="step-ul">  
                      <li>Войдите в раздел Мои платежи, в kaspi приложении</li> 
                      <li>Введите в поиске EasyENT</li>  
                      <li>Перейдите в раздел EasyENT, образование</li> 
                      <li>Введите номер заказа:</li> 
                      <li>Оплатите заказ</li> 
                  </ul>
                </div>
                <div className="payment-info">
                  <span className="payment-info__heading2">Если цена неверная</span>
                  <p>Напишите пожалуйста нашему менеджеру в телеграм: @Raiym_Dauken или по номеру телефона 
                  +7 70 200 25 66 (Раим)</p>
                </div> 
              </div> : "" 
            }
        </div>
         : (
          <>
          {userFeeds.filter(e => e.start_date == "2024-09-09").length > 0  ? (
            <div className="payment-item">За сентябрь курсы оплачены</div>
          ) : (
            <div className="payment-item">
              Пусто, напишите менеджеру EasyENT: <b>8 708 555 01 42 </b> 
            </div>
             )
          }   
          </>
        )
      }
      </>
    )
  }
  const TabOct = () => {
    const [homeworkGrades, setHomeworkGrades] = useState<number>(0); // Only stores overall percentage
    const order = orders.filter((o) => o.start_date === "2024-10-09").sort((o) => o.id).reverse()[0];
    const is_userfeeds = userFeeds.filter(e => e.start_date === "2024-10-09").length > 0;
  
    // Fetch homework grades for each position in order
    useEffect(() => {
      if (order && order.positions) {
        const fetchOverallHomework = async () => {
          try {
            // Fetch data for each course_id separately
            const responses = await Promise.all(
              order.positions.map((position: any) =>
                axios.get(`/api/v1/homework/overall_lessons_homeworks?start_date=2024-09-09&end_date=2024-10-09&course_id=${position.course_id}`, {
                  headers: authHeader()
                })
              )
            );
            // Combine the results
            const allHomeworkGrades = responses.map((response) => {
              const data = response.data;
              // If the response array is empty, set homework_is_correct_percent to 0
              if (data.length === 0) {
                return { homework_is_correct_percent: 0 };
              }
              // Otherwise, return the first element from the response array
              return data[0];
            });
  
            // Calculate the total percentage
            const totalPercent = allHomeworkGrades.reduce((acc: number, course: any) => acc + course.homework_is_correct_percent, 0);
  
            // Calculate the overall percentage
            const overallPercent = allHomeworkGrades.length > 0 ? totalPercent / allHomeworkGrades.length : 0;
  
            // Set the overall percentage in the state
            setHomeworkGrades(overallPercent);
          } catch (error) {
            console.error("Error fetching homework grades:", error);
          }
        };
  
        fetchOverallHomework();
      }
    }, [order]);
  
    return (
      <>
        {order ? (
          <div className="payment-item box">
            <span className="payment-item__title">Оплата за период с 9 октября по 8 ноября</span>
            <div className="payment-row">
              <p className="payment-row__title">Номер заказа</p>
              <p>{order.id}</p>
            </div>
            <div className="payment-row">
              <p className="payment-row__title">Мои курсы</p>
              <ul>
                {order.positions ? order.positions.map((item: any) => (
                  <li key={item.order_id}>{item.course?.name}</li>
                )) : ""}
              </ul>
            </div>
            <div className="payment-row">
              <p className="payment-row__title">Общий процент выполнения домашних заданий</p>
              <p>{homeworkGrades > 85 ? "Cкидка 10%, для получение скидки напишите @sainsemey" : "Cкидки нету"}</p>  
              <p>Общий процент дз: {homeworkGrades.toFixed(2)}%</p>
            </div>
            <div className="payment-row">
              <p className="payment-row__title">Стоимость</p>
              <p>{order.total_amount}</p>
            </div>
            <div className="payment-row payment-row--last">
              <p className="payment-row__title">Статус</p>
              {is_userfeeds ? (
                <p><i className="fa-solid fa-check"></i> Оплачено</p>
              ) : (
                <p><i className="fa-solid fa-xmark"></i> Не оплачено</p>
              )}
            </div>
            {order && !is_userfeeds && (
              <div className="payment-bottom">
                <form
                  target="_blank"
                  method="post"
                  action="https://kaspi.kz/online"
                  id="formOnlinePayment"
                >
                  <input type="hidden" name="TranId" value={order.id + ""} />
                  <input type="hidden" name="OrderId" value={order.id + ""} />
                  <input type="hidden" name="Amount" value={order.total_amount * 100 + ""} />
                  <input type="hidden" name="Service" value="StayStudy" />
                  <input type="hidden" name="ReturnUrl" value={"https://app.easyent.kz/transaction/success?id=" + order.id} />
                  <button className="btn btn-primary btn-flex">Оплатить через Kaspi</button>
                </form>
                <div className="payment-info">
                  <span className="payment-info__heading2">Если оплата не выходит при нажатии на кнопку оплатить</span>
                  <ul className="step-ul">
                    <li>Откройте раздел "мои платежи" в kaspi приложении</li>
                    <li>Введите в поиске EasyENT</li>
                    <li>Перейдите в раздел EasyENT образование</li>
                    <li>Введите номер заказа: <b>{order.id}</b></li>
                    <li>Оплатите заказ</li>
                  </ul>
                </div>
                <div className="payment-info">
                  <span className="payment-info__heading2">Если хотите поменять предметы или пакет</span>
                  <p>Напишите в телеграм @sainsemey или по номеру телефона +7 775 026 58 36 (Саин)</p>
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            {is_userfeeds ? (
              <div className="payment-item">За октябрь курсы оплачены</div>
            ) : (
              <div className="payment-item">Пусто, платежи скоро сгенерируют</div>
            )}
          </>
        )}
      </>
    );
  };
  const TabNov = () => {
    const [homeworkGrades, setHomeworkGrades] = useState<number>(0); // Only stores overall percentage
    const order = orders.filter((o) => o.start_date === "2024-11-09").sort((o) => o.id).reverse()[0];
    const is_userfeeds = userFeeds.filter(e => e.start_date === "2024-11-09").length > 0;
  
    // Fetch homework grades for each position in order
    useEffect(() => {
      if (order && order.positions) {
        const fetchOverallHomework = async () => {
          try {
            // Fetch data for each course_id separately
            const responses = await Promise.all(
              order.positions.map((position: any) =>
                axios.get(`/api/v1/homework/overall_lessons_homeworks?start_date=2024-10-09&end_date=2024-11-09&course_id=${position.course_id}`, {
                  headers: authHeader()
                })
              )
            );
  
            // Combine the results
            const allHomeworkGrades = responses.map((response) => {
              const data = response.data;
              // If the response array is empty, set homework_is_correct_percent to 0
              if (data.length === 0) {
                return { homework_is_correct_percent: 0 };
              }
              // Otherwise, return the first element from the response array
              return data[0];
            });
  
            // Calculate the total percentage
            const totalPercent = allHomeworkGrades.reduce((acc: number, course: any) => acc + course.homework_is_correct_percent, 0);
  
            // Calculate the overall percentage
            const overallPercent = allHomeworkGrades.length > 0 ? totalPercent / allHomeworkGrades.length : 0;
  
            // Set the overall percentage in the state
            setHomeworkGrades(overallPercent);
          } catch (error) {
            console.error("Error fetching homework grades:", error);
          }
        };
  
        fetchOverallHomework();
      }
    }, [order]);
  
    return (
      <>
        {order ? (
          <div className="payment-item box">
            <span className="payment-item__title">Оплата за период с 9 ноября по 8 декабря</span>
            <div className="payment-row">
              <p className="payment-row__title">Номер заказа</p>
              <p>{order.id}</p>
            </div>
            <div className="payment-row">
              <p className="payment-row__title">Мои курсы</p>
              <ul>
                {order.positions ? order.positions.map((item: any) => (
                  <li key={item.order_id}>{item.course?.name}</li>
                )) : ""}
              </ul>
            </div>
            <div className="payment-row">
              <p className="payment-row__title">Общий процент выполнения домашних заданий</p>
              <p>{homeworkGrades > 85 ? "Cкидка 10%, для получение скидки напишите @dana_manager_ent" : "Cкидки нету, чтобы получить скидку %дз должен быть больше 85%"}</p>  
              <p>Общий процент дз: {homeworkGrades.toFixed(2)}%</p>
            </div>
            <div className="payment-row">
              <p className="payment-row__title">Стоимость</p>
              <p>{order.total_amount}</p>
            </div>
            <div className="payment-row payment-row--last">
              <p className="payment-row__title">Статус</p>
              {is_userfeeds ? (
                <p><i className="fa-solid fa-check"></i> Оплачено</p>
              ) : (
                <p><i className="fa-solid fa-xmark"></i> Не оплачено</p>
              )}
            </div>
            {order && !is_userfeeds && (
              <div className="payment-bottom">
                <form
                  target="_blank"
                  method="post"
                  action="https://kaspi.kz/online"
                  id="formOnlinePayment"
                >
                  <input type="hidden" name="TranId" value={order.id + ""} />
                  <input type="hidden" name="OrderId" value={order.id + ""} />
                  <input type="hidden" name="Amount" value={order.total_amount * 100 + ""} />
                  <input type="hidden" name="Service" value="StayStudy" />
                  <input type="hidden" name="ReturnUrl" value={"https://app.easyent.kz/transaction/success?id=" + order.id} />
                  <button className="btn btn-primary btn-flex">Оплатить через Kaspi</button>
                </form>
                <div className="payment-info">
                  <span className="payment-info__heading2">Если оплата не выходит при нажатии на кнопку оплатить</span>
                  <ul className="step-ul">
                    <li>Откройте раздел "мои платежи" в kaspi приложении</li>
                    <li>Введите в поиске EasyENT</li>
                    <li>Перейдите в раздел EasyENT образование</li>
                    <li>Введите номер заказа: <b>{order.id}</b></li>
                    <li>Оплатите заказ</li>
                  </ul>
                </div>
                <div className="payment-info">
                  <span className="payment-info__heading2">Если хотите поменять предметы или пакет</span>
                  <p>Напишите в телеграм @dana_manager_ent или по номеру телефона +7 702 718 37 46 (Дана)</p>
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            {is_userfeeds ? (
              <div className="payment-item">За ноябрь курсы оплачены</div>
            ) : (
              <div className="payment-item">Пусто, платежи скоро сгенерируют</div>
            )}
          </>
        )}
      </>
    );
  };
  
  const TabDec = () => {
    const [homeworkGrades, setHomeworkGrades] = useState<number>(0); // Only stores overall percentage
    const order = orders.filter((o) => o.start_date === "2024-12-09" || o.start_date === "2024-12-11").sort((o) => o.id).reverse()[0];
    const is_userfeeds = userFeeds.filter(e => e.start_date === "2024-12-09").length > 0 || userFeeds.filter(e => e.start_date === "2024-12-11").length > 0;
  
    useEffect(() => {
      if (order && order.positions) {
        const fetchOverallHomework = async () => {
          try {
            const responses = await Promise.all(
              order.positions.map((position: any) =>
                axios.get(`/api/v1/homework/overall_lessons_homeworks?start_date=2024-11-09&end_date=2024-12-09&course_id=${position.course_id}`, {
                  headers: authHeader()
                })
              )
            );
            const allHomeworkGrades = responses.map((response) => {
              const data = response.data;
              if (data.length === 0) {
                return { homework_is_correct_percent: 0 };
              }
              return data[0];
            });
  
            const totalPercent = allHomeworkGrades.reduce((acc: number, course: any) => acc + course.homework_is_correct_percent, 0);
            const overallPercent = allHomeworkGrades.length > 0 ? totalPercent / allHomeworkGrades.length : 0;
  
            setHomeworkGrades(overallPercent);
          } catch (error) {
            console.error("Error fetching homework grades:", error);
          }
        };
  
        fetchOverallHomework();
      }
    }, [order]);
  
    return (
      <>
        {order ? (
          <div className="payment-item box">
            <span className="payment-item__title">{order.start_date == "2024-12-09" ? "Оплата за период с 9 декабря по 8 января" : "Оплата за период с 11 декабря 2024 г по 10 января 2025 г"}</span>
            <div className="payment-row">
              <p className="payment-row__title">Номер заказа</p>
              <p>{order.id}</p>
            </div>
            <div className="payment-row">
              <p className="payment-row__title">Мои курсы</p>
              <ul>
                {order.positions ? order.positions.map((item: any) => (
                  <li key={item.order_id}>{item.course?.name}</li>
                )) : ""}
              </ul>
            </div>
            <div className="payment-row">
              <p className="payment-row__title">Общий процент выполнения домашних заданий</p>
              <p>{homeworkGrades > 85 ? "Cкидка 10%, для получение скидки напишите @dana_manager_ent" : "Cкидки нету"}</p>  
              <p>Общий процент дз: {homeworkGrades.toFixed(2)}%</p>
            </div>
            <div className="payment-row">
              <p className="payment-row__title">Стоимость</p>
              <p>{order.total_amount}</p>
            </div>
            <div className="payment-row">
              <p className="payment-row__title">Скидка:</p>
              <p></p>
            </div>
            <div className="payment-row payment-row--last">
              <p className="payment-row__title">Статус</p>
              {is_userfeeds ? (
                <p><i className="fa-solid fa-check"></i> Оплачено</p>
              ) : (
                <p><i className="fa-solid fa-xmark"></i> Не оплачено</p>
              )}
            </div>
            {order && !is_userfeeds && (
              <div className="payment-bottom">
                <form
                  target="_blank"
                  method="post"
                  action="https://kaspi.kz/online"
                  id="formOnlinePayment"
                >
                  <input type="hidden" name="TranId" value={order.id + ""} />
                  <input type="hidden" name="OrderId" value={order.id + ""} />
                  <input type="hidden" name="Amount" value={order.total_amount * 100 + ""} />
                  <input type="hidden" name="Service" value="StayStudy2" />
                  <input type="hidden" name="ReturnUrl" value={"https://app.easyent.kz/transaction/success?id=" + order.id} />
                  <button className="btn btn-primary btn-flex">Оплатить через Kaspi</button>
                </form>
                <div className="payment-info">
                  <span className="payment-info__heading2">Если оплата не выходит при нажатии на кнопку оплатить</span>
                  <ul className="step-ul">
                    <li>Откройте раздел "мои платежи" в kaspi приложении</li>
                    <li>Введите в поиске EasyENT</li>
                    <li>Перейдите в раздел EasyENT образование</li>
                    <li>Введите номер заказа: <b>{order.id}</b></li>
                    <li>Оплатите заказ</li>
                  </ul>
                </div>
                <div className="payment-info">
                  <span className="payment-info__heading2">Если хотите поменять предметы или пакет</span>
                  <p>Напишите в телеграм @dana_manager_ent или по номеру телефона +7 702 718 37 46 (Дана)</p>
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            {is_userfeeds ? (
              <div className="payment-item">За декабрь курсы оплачены</div>
            ) : (
              <div className="payment-item">Пусто, платежи скоро сгенерируют</div>
            )}
          </>
        )}
      </>
    );
  };

  const TabJan = () => {
    const [homeworkGrades, setHomeworkGrades] = useState<number>(0); // Only stores overall percentage
    const order = orders.filter((o) => o.start_date === "2025-01-09" || o.start_date === "2025-01-11").sort((o) => o.id).reverse()[0];
    const is_userfeeds = userFeeds.filter(e => e.start_date === "2025-01-09").length > 0 || userFeeds.filter(e => e.start_date === "2025-01-11").length > 0;
  
    useEffect(() => {
      if (order && order.positions) {
        const fetchOverallHomework = async () => {
          try {
            const responses = await Promise.all(
              order.positions.map((position: any) =>
                axios.get(`/api/v1/homework/overall_lessons_homeworks?start_date=2024-12-09&end_date=2025-01-09&course_id=${position.course_id}`, {
                  headers: authHeader()
                })
              )
            );
            const allHomeworkGrades = responses.map((response) => {
              const data = response.data;
              if (data.length === 0) {
                return { homework_is_correct_percent: 0 };
              }
              return data[0];
            });
  
            const totalPercent = allHomeworkGrades.reduce((acc: number, course: any) => acc + course.homework_is_correct_percent, 0);
            const overallPercent = allHomeworkGrades.length > 0 ? totalPercent / allHomeworkGrades.length : 0;
  
            setHomeworkGrades(overallPercent);
          } catch (error) {
            console.error("Error fetching homework grades:", error);
          }
        };
  
        fetchOverallHomework();
      }
    }, [order]);
  
    return (
      <>
        {order ? (
          <div className="payment-item box">
            <span className="payment-item__title">{order.start_date == "2025-01-09" ? "Оплата за период с 9 января по 8 февраля" : "Оплата за период с 11 января 2025 г по 10 февраля 2025 г"}</span>
            <div className="payment-row">
              <p className="payment-row__title">Номер заказа</p>
              <p>{order.id}</p>
            </div>
            <div className="payment-row">
              <p className="payment-row__title">Мои курсы</p>
              <ul>
                {order.positions ? order.positions.map((item: any) => (
                  <li key={item.order_id}>{item.course?.name}</li>
                )) : ""}
              </ul>
            </div>
            <div className="payment-row">
              <p className="payment-row__title">Общий процент выполнения домашних заданий</p>
              <p>{homeworkGrades > 85 ? "Cкидка 10%, для получение скидки напишите @dana_manager_ent" : "Cкидки нету"}</p>  
              <p>Общий процент дз: {homeworkGrades.toFixed(2)}%</p>
            </div>
            <div className="payment-row">
              <p className="payment-row__title">Стоимость</p>
              <p>{order.total_amount}</p>
            </div>
            <div className="payment-row">
              <p className="payment-row__title">Скидка:</p>
              <p></p>
            </div>
            <div className="payment-row payment-row--last">
              <p className="payment-row__title">Статус</p>
              {is_userfeeds ? (
                <p><i className="fa-solid fa-check"></i> Оплачено</p>
              ) : (
                <p><i className="fa-solid fa-xmark"></i> Не оплачено</p>
              )}
            </div>
            {order && !is_userfeeds && (
              <div className="payment-bottom">
                <form
                  target="_blank"
                  method="post"
                  action="https://kaspi.kz/online"
                  id="formOnlinePayment"
                >
                  <input type="hidden" name="TranId" value={order.id + ""} />
                  <input type="hidden" name="OrderId" value={order.id + ""} />
                  <input type="hidden" name="Amount" value={order.total_amount * 100 + ""} />
                  <input type="hidden" name="Service" value="StayStudy2" />
                  <input type="hidden" name="ReturnUrl" value={"https://app.easyent.kz/transaction/success?id=" + order.id} />
                  <button className="btn btn-primary btn-flex">Оплатить через Kaspi</button>
                </form>
                <div className="payment-info">
                  <span className="payment-info__heading2">Если оплата не выходит при нажатии на кнопку оплатить</span>
                  <ul className="step-ul">
                    <li>Откройте раздел "мои платежи" в kaspi приложении</li>
                    <li>Введите в поиске EasyENT</li>
                    <li>Перейдите в раздел EasyENT образование</li>
                    <li>Введите номер заказа: <b>{order.id}</b></li>
                    <li>Оплатите заказ</li>
                  </ul>
                </div>
                <div className="payment-info">
                  <span className="payment-info__heading2">Если хотите поменять предметы или пакет</span>
                  <p>Напишите в телеграм @dana_manager_ent или по номеру телефона +7 702 718 37 46 (Дана)</p>
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            {is_userfeeds ? (
              <div className="payment-item">За январь курсы оплачены</div>
            ) : (
              <div className="payment-item">Пусто, платежи скоро сгенерируют</div>
            )}
          </>
        )}
      </>
    );
  };
  const tabs = [
    {
      label: "Январь",
      Component: TabJan
    },
    {
      label: "Декабрь",
      Component: TabDec
    },
    {
      label: "Ноябрь",
      Component: TabNov
    },
    {
      label: "Октябрь",
      Component: TabOct
    },
    {
      label: "Сентябрь",
      Component: TabSt
    },
    
  ]
  return (
    <div className="subscription-content">
      <h1 className="title">Мои оплаты</h1>
      <Tabs tabs={tabs} />
    </div>
  );
};
