// import { useParams} from "react-router-dom";
import moment from "moment";
import "moment/locale/ru";
moment.locale("ru");

export const TeacherSalary = () => {
  document.title = "Курсы | EasyENT";
  return (
    <div>
      <h1 className="title">Зарплаты</h1>
      <div className="box">

      </div>
    </div>
  );
};
