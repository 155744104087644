import axios from "axios";
import React, { useEffect, useState} from "react";
import { OverallHomework } from "src/interface";
import { useParams, Link } from "react-router-dom";
import { authHeader } from "src/utils/authHeader";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import "./AnalitycsPage.scss";

export const AnalitycsPage = () => {
  document.title = "Аналитика ошибок | EasyENT";
  const params = useParams();
  const [lessonsAnalytics, setLessonsAnalytics] = useState<OverallHomework []>([]);
  const [octlessonsAnalytics, setOctLessonsAnalytics] = useState<OverallHomework []>([]);
  const [novlessonsAnalytics, setNovLessonsAnalytics] = useState<OverallHomework []>([]);
  const [declessonsAnalytics, setDecLessonsAnalytics] = useState<OverallHomework []>([]);
  const [janlessonsAnalytics, setJanLessonsAnalytics] = useState<OverallHomework []>([]);
  const [sepOverallHomework, setSepOverallHomework] = useState<OverallHomework[]>([]);
  const [octOverallHomework, setOctOverallHomework] = useState<OverallHomework[]>([]);
  const [novOverallHomework, setNovOverallHomework] = useState<OverallHomework[]>([]);
  const [decOverallHomework, setDecOverallHomework] = useState<OverallHomework[]>([]);
  const [janOverallHomework, setJanOverallHomework] = useState<OverallHomework[]>([]);
  useEffect(() => {
    axios
      .get("/api/v1/homework/course/" + params.id + "/lessons_homeworks?start_date=2024-09-09&end_date=2024-10-09", { headers: authHeader() })
      .then((response) => {
        setLessonsAnalytics(response.data);
    });
    axios
      .get("/api/v1/homework/course/" + params.id + "/lessons_homeworks?start_date=2024-10-09&end_date=2024-11-09", { headers: authHeader() })
      .then((response) => {
        setOctLessonsAnalytics(response.data);
    });
    axios
      .get("/api/v1/homework/course/" + params.id + "/lessons_homeworks?start_date=2024-11-09&end_date=2024-12-09", { headers: authHeader() })
      .then((response) => {
        setNovLessonsAnalytics(response.data);
    });
    axios
      .get("/api/v1/homework/course/" + params.id + "/lessons_homeworks?start_date=2024-12-09&end_date=2025-01-09", { headers: authHeader() })
      .then((response) => {
        setDecLessonsAnalytics(response.data);
    });
    axios
      .get("/api/v1/homework/course/" + params.id + "/lessons_homeworks?start_date=2025-01-09&end_date=2025-02-09", { headers: authHeader() })
      .then((response) => {
        setJanLessonsAnalytics(response.data);
    });


    axios
      .get("/api/v1/homework/overall_lessons_homeworks?start_date=2024-09-09&end_date=2024-10-08&course_id=" + params.id, {
        headers: authHeader(),
      })
      .then((response) => {
        setSepOverallHomework(response.data);
      });
    axios
      .get("/api/v1/homework/overall_lessons_homeworks?start_date=2024-10-09&end_date=2024-11-08&course_id=" + params.id, {
        headers: authHeader(),
      })
      .then((response) => {
        setOctOverallHomework(response.data);
      });  
    axios
      .get("/api/v1/homework/overall_lessons_homeworks?start_date=2024-11-09&end_date=2024-12-08&course_id=" + params.id, {
        headers: authHeader(),
      })
      .then((response) => {
        setNovOverallHomework(response.data);
      });
    axios
      .get("/api/v1/homework/overall_lessons_homeworks?start_date=2024-12-09&end_date=2025-01-08&course_id=" + params.id, {
        headers: authHeader(),
      })
      .then((response) => {
        setDecOverallHomework(response.data);
      });
    axios
      .get("/api/v1/homework/overall_lessons_homeworks?start_date=2025-01-09&end_date=2025-02-08&course_id=" + params.id, {
        headers: authHeader(),
      })
      .then((response) => {
        setJanOverallHomework(response.data);
      });      
  }, []);
  const [activeTab, setActiveTab] = useState('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    console.log("Tab changed to: ", newValue); // Check if the function is triggered
    setActiveTab(newValue);
  };
  return (
    <>
      <div className="box">
        <div className="analize-top">
          <h1 className="analize-top__heading">Аналитика ошибок</h1>
        </div>
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} variant="scrollable" allowScrollButtonsMobile={true} aria-label="lab API tabs example">
              <Tab label="Сентябрь" value="1" />
              <Tab label="Октябрь" value="2" />
              <Tab label="Ноябрь" value="3" />
              <Tab label="Декабрь" value="4" />
              <Tab label="Январь" value="5" />
            </TabList>
          </Box>
          <TabPanel className="analytics-panel" value="1">
            <span className="analytics-panel__heading">Общии процент решение дз: {sepOverallHomework.length > 0 && sepOverallHomework[0].homework_is_correct_percent ? 
              `${sepOverallHomework[0].homework_is_correct_percent.toFixed(0)}%` 
            : "0%"
            } </span>
            <ul className="anal-ul">
            {lessonsAnalytics.length ?
              lessonsAnalytics.map((lesson, index) =>
                (
                  <li key={index}>
                    <Link to={`lesson_${lesson.lesson_id}`} className="lesson-analize">
                      <span className="lesson-analize__heading">
                        {lesson.name}
                      </span>
                      <div className="lesson-analize__row">
                        <p>Процент дз: {lesson.homework_is_correct_percent} %</p>
                      </div>
                      <div className="lesson-analize__row">
                        <p>Правильно: {lesson.homework_is_correct_count} из {lesson.question_count}</p>
                      </div>
                      <span className="btn btn-primary">Смотреть решения</span>
                    </Link>
                  </li>
                )) : ""
            }
            </ul>
          </TabPanel>
          <TabPanel className="analytics-panel" value="2">
            <span className="analytics-panel__heading">Общии процент решение дз: {novOverallHomework.length > 0 && novOverallHomework[0].homework_is_correct_percent ? 
              `${novOverallHomework[0].homework_is_correct_percent.toFixed(0)}%` 
            : "0%"
            } </span>
            <ul className="anal-ul">
            {octlessonsAnalytics.length ?
              octlessonsAnalytics.map((lesson, index) =>
                (
                  <li key={index}>
                    <Link to={`lesson_${lesson.lesson_id}`} className="lesson-analize">
                      <span className="lesson-analize__heading">
                        {lesson.name}
                      </span>
                      <div className="lesson-analize__row">
                        <p>Процент дз: {lesson.homework_is_correct_percent} %</p>
                      </div>
                      <div className="lesson-analize__row">
                        <p>Правильно: {lesson.homework_is_correct_count} из {lesson.question_count}</p>
                      </div>
                      <span className="btn btn-primary">Смотреть решения</span>
                    </Link>
                  </li>
                )) : ""
            }
            </ul>
          </TabPanel>
          <TabPanel className="analytics-panel" value="3">
            <span className="analytics-panel__heading">Общии процент решение дз: {octOverallHomework.length > 0 && octOverallHomework[0].homework_is_correct_percent ? 
              `${octOverallHomework[0].homework_is_correct_percent.toFixed(0)}%` 
            : "0%"
            } </span>
            <ul className="anal-ul">
            {novlessonsAnalytics.length ?
              novlessonsAnalytics.map((lesson, index) =>
                (
                  <li key={index}>
                    <Link to={`lesson_${lesson.lesson_id}`} className="lesson-analize">
                      <span className="lesson-analize__heading">
                        {lesson.name}
                      </span>
                      <div className="lesson-analize__row">
                        <p>Процент дз: {lesson.homework_is_correct_percent} %</p>
                      </div>
                      <div className="lesson-analize__row">
                        <p>Правильно: {lesson.homework_is_correct_count} из {lesson.question_count}</p>
                      </div>
                      <span className="btn btn-primary">Смотреть решения</span>
                    </Link>
                  </li>
                )) : ""
            }
            </ul>
          </TabPanel>
          <TabPanel className="analytics-panel" value="4">
            <span className="analytics-panel__heading">Общии процент решение дз: {decOverallHomework.length > 0 && decOverallHomework[0].homework_is_correct_percent ? 
              `${decOverallHomework[0].homework_is_correct_percent.toFixed(0)}%` 
            : "0%"
            } </span>
            <ul className="anal-ul">
            {declessonsAnalytics.length ?
              declessonsAnalytics.map((lesson, index) =>
                (
                  <li key={index}>
                    <Link to={`lesson_${lesson.lesson_id}`} className="lesson-analize">
                      <span className="lesson-analize__heading">
                        {lesson.name}
                      </span>
                      <div className="lesson-analize__row">
                        <p>Процент дз: ({lesson.homework_is_correct_count} * 100 / {lesson.question_count}) + %</p>
                      </div>
                      <div className="lesson-analize__row">
                        <p>Правильно: {lesson.homework_is_correct_count} из {lesson.question_count}</p>
                      </div>
                      <span className="btn btn-primary">Смотреть решения</span>
                    </Link>
                  </li>
                )) : ""
            }
            </ul>
          </TabPanel>
          <TabPanel className="analytics-panel" value="5">
            <span className="analytics-panel__heading">Общии процент решение дз: {janOverallHomework.length > 0 && janOverallHomework[0].homework_is_correct_percent ? 
              `${janOverallHomework[0].homework_is_correct_percent.toFixed(0)}%` 
            : "0%"
            } </span>
            <ul className="anal-ul">
            {janlessonsAnalytics.length ?
              janlessonsAnalytics.map((lesson, index) =>
                (
                  <li key={index}>
                    <Link to={`lesson_${lesson.lesson_id}`} className="lesson-analize">
                      <span className="lesson-analize__heading">
                        {lesson.name}
                      </span>
                      <div className="lesson-analize__row">
                        <p>Процент дз: ({lesson.homework_is_correct_count} * 100 / {lesson.question_count}) + %</p>
                      </div>
                      <div className="lesson-analize__row">
                        <p>Правильно: {lesson.homework_is_correct_count} из {lesson.question_count}</p>
                      </div>
                      <span className="btn btn-primary">Смотреть решения</span>
                    </Link>
                  </li>
                )) : ""
            }
            </ul>
          </TabPanel>
      </TabContext>
        
      </div>
    </>
  );
};
