import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ITeacherItem } from "src/interface";
import { authHeader } from "src/utils/authHeader";

export const AdminTeacherPage = () => {  
  document.title = "Преподаватели | EasyENT";

  const [teachers, setAllTeachers] = useState<ITeacherItem[]>([]);
  useEffect(() => {
    axios
      .get("/api/v1/teacher/",{ headers: authHeader()})
      .then((response) => setAllTeachers(response.data));
  }, []);
  return (
    <div>
      <h1 className="title">Учителя</h1>
      <div className="admintop-buttons">
        <Link className="btn btn-primary " to="ad">Добавить +</Link>
      </div>  
      <table className="table">
        <tr>
          <th>
            Id
          </th>
          <th>
            Фио
          </th>          
          <th>
            Номер телефона
          </th>
          <th>
            Предме
          </th>
          <th>
            Управление
          </th>
        </tr>
        {teachers.map((teacher) => (
          <tr key={teacher.id}>
            <td>{teacher.id}</td>
            <td>{teacher.name}</td>
            <td></td>
            <td>{teacher.image}</td>
            <td>
              <div className="item-control">
                <div className="item-control__item">
                  <i className="fa-solid fa-pen"></i>
                </div>
                <div className="item-control__item">
                  <i className="fa-solid fa-eye"></i>
                </div>
                <div className="item-control__item">
                  <i className="fa-solid fa-xmark"></i>
                </div>
              </div>
            </td>
          </tr>
        ))}
      </table>    
    </div>
  );
};
