import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { ICourse,  ILesson} from "src/interface";
import { authHeader } from "src/utils/authHeader";
import { MyLessonItem } from "src/components";
import { useAppSelector } from "src/hooks/redux";

export const ViewCoursePageOld = () => {
  const params = useParams();
  const user = useAppSelector((store) => store.user.data);
  const [course, setCourse] = useState<ICourse>();
  const [lessons, setLessons] = useState<ILesson[]>([]);
  const [lessonCompleted, setLessonCompleted] = useState<any>([]);
  function getCompletedLessons() {
    axios
      .get(`api/v1/courses/${params?.id}/lesson_completed_count`, {
        headers: authHeader(),
      })
      .then((res: any) => setLessonCompleted(res?.data));
  }
  useEffect(() => {
    axios
      .get("/api/v1/courses/" + params.id, { headers: authHeader() })
      .then((response) => {
        setCourse(response.data);
    });
    axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2022-01-09&to_date=2024-10-09&skip=0&limit=100", {
        headers: authHeader(),
      })
      .then((response) => {
        setLessons(response.data);
      });
    getCompletedLessons();
  }, []);
  
  return (
    <div className="view-course">
      {course ? (
        <div className="course-info box">
          <div className="course-info__top">
            <span className="course-info__heading">
              {course.name}
            </span>
            <div className="course-info__right">
              <Link className="back-btn" to="/my-courses">
                <i className="fa-solid fa-arrow-left"></i>Назад
              </Link>
            </div>
          </div>
          {course && course.id === 8269 ?
            <div className="course-info__bot">
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://salebot.site/easyent_start_1?userid=${user.id}`}
                    className="btn btn-primary btn-telegram btn-flex"
                  > Телеграм группа
                    <img src="assets/svg/telegram.svg" />
                </a>
            </div> : ""
          }      
        </div>
        ) : (
        "Загрузка"
      )}
      <div className="last-lesson">
        {
          course && lessons ? (
            <div className="lesson-panel box">
              <ul className="lesson-list"> 
                {lessons.map((lesson, index) =>
                  lesson.start_date ? (
                    <li key={lesson.id}>
                      <MyLessonItem
                        lesson={lesson}
                        index={index}
                        without_penalty={course ? course.without_penalty : false}
                        completed={lessonCompleted}
                      />
                    </li>
                  ) : (
                    ""
                  )
                )}
              </ul>   
            </div>
          ) : ""  
        }  
      </div>
    </div>
  );
};