import axios from "axios";
import React, { useEffect, useState } from "react";
import {  AdminLesson } from "src/interface";
import { useParams, Link } from "react-router-dom";
import { authHeader } from "src/utils/authHeader";

export const AdminViewVideo = () => {
  document.title = "Дз правка | EasyENТ";
  const params = useParams();
  const [lesson, setLesson] = useState<AdminLesson>({});
  useEffect(() => {
    axios
      .get("/api/v1/lessons/" + params.lesson_id, { headers: authHeader() })
      .then((response) => {
        const myLesson = response.data;
        setLesson({
          id: myLesson.id,
          name: myLesson.name,
          start_date: myLesson.start_date,
          end_date: myLesson.end_date,
          zoom_link: myLesson.zoom_link,
          video_url: myLesson.video_url,
          conspected: myLesson.conspected,
          course_id: myLesson.course_id,
          course_name: myLesson.course.name,
        })
      });
  }, []);

  return (
    <div className="my-course">
      <ul className="breadcrumbs">
        <li>
          <Link to="/admin/courses">Мои курсы</Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <Link to={`/admin/courses/course/${lesson?.course_id}`}>{lesson?.course_name}</Link>
        </li>
        <li>
          <span>
            {lesson?.id && <i className="fa-solid fa-arrow-right"></i>}
          </span>
        </li>
        <li>
          <span>{lesson?.id && lesson?.name}</span>
        </li>
      </ul>
      <h1 className="title">Запись урока</h1>
      <div className="video-lesson box">
        <div className="video-lesson__top">
          <div className="video-lesson__left">
            <span className="video-lesson__heading">{lesson.name}</span>
            <span className="video-lesson__date">
              sdsjdjdsk
              {/* <i className="fa-solid fa-clock"></i> {lesson_day} */}
            </span>
          </div>
        </div>
        {lesson.video_url !== null && lesson.video_url !== "" ? (
          <div className="video-lesson__img">
            <iframe
              src={lesson.video_url + "?controls=0"}
              title="YouTube video player"
              allow="accelerometer; autoplay;modestbranding=1; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        ) : lesson.zoom_link !== null ? (
          <a target="_blank" rel="noreferrer" href={lesson.zoom_link}>
            {lesson.zoom_link}
          </a>
        ) : (
          <p>Видеоурока нету проверка</p>
        )}
      </div>
    </div>
  );
};
