import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { useParams,  useLocation, Link } from "react-router-dom";
import { useForm} from "react-hook-form";
import { ILesson, ICourse, QuestionType } from "src/interface";
import "./HomeWorkPage.scss";
import { authHeader } from "src/utils/authHeader";
import { Popconfirm } from "src/components";
import { ModalAttachment } from "src/components";
import moment from "moment";

export const HomeWorkPage = () => {
  document.title = "Домашнее задание | EasyENT";
  const params = useParams();
  const location: any = useLocation();
  const [course, setCourse] = useState<ICourse>();
  const [lesson, setlesson] = useState<ILesson>({});
  const [visible, setVisible] = useState(false);
  const [corrcetCount, setCorrcetCount] = useState<number>(0);
  const [homeworkPercent, setHomeworkPercent] = useState<number | undefined>(0);
  const [homeworkDate, setHomeworkDate] = useState<Date | undefined>();
  const {
    register,
    handleSubmit,
  } = useForm();
  const [h_attachment, setH_attachment] = useState({ photofile: "string"});
  const showPopconfirm = useCallback(() => {
    setVisible((value: boolean) => !value);
  }, []);
  const [attachamentModalActive, setAttachamentModalActive] = useState(false);
  const [attachmentPath, setAttachmentPath] = useState<string | null>(null);
  
  useEffect(() => {
    axios
      .get("/api/v1/courses/" + params.course_id, { headers: authHeader() })
      .then((response) => {
        setCourse(response.data);
    });
  }, []);  

  useEffect(() => {
    let correctNumber = 0;
    axios
      .get("/api/v1/lessons/" + params.id + "/homeworks", { headers: authHeader() })
      .then((response) => {
        setlesson(response.data);
        if(response.data.questions[0]["homeworks"].length){
          setHomeworkDate(response.data.questions[0]["homeworks"][0]["write_date"]);
        }
        setHomeworkPercent(response.data.correct_homework_percent);
        for (const q in response.data.questions) {
          for (const h in response.data.questions[q]["homeworks"]) {
            register("value" + response.data.questions[q]["id"], {
              value: response.data.questions[q]["homeworks"][h]["value"],
              disabled: true,
            });
            if(response.data.questions[q]["homeworks"][h]["is_correct"] == true){
              correctNumber += 1;
            }
          }
        }
        setCorrcetCount(correctNumber);
      });
  }, [h_attachment]);

  const answered = function (id: number) {
    if (lesson && lesson.questions) {
      const question = lesson.questions.filter(
        (q) =>
          q.id === id &&
          q.homeworks &&
          typeof (q.homeworks, "array") &&
          q.homeworks.length > 0
      );
      if (question.length > 0) {
        return true;
      }
    }
    return false;
  };

  const correctAnswer = function (
    id: number,
    value: string | undefined
  ): string {
    if (lesson && lesson.questions && value) {
      const question = lesson.questions.filter(
        (q) =>
          q.id === id &&
          q.homeworks &&
          typeof (q.homeworks, "array") &&
          q.homeworks.length > 0 &&
          q.homeworks[0].value === value
      );

      if (question.length > 0) {
        if (question[0]?.homeworks?.[0]?.is_correct == false) {
          return "input-radio__incorrect";
        } else if (question[0]?.homeworks?.[0]?.is_correct == true) {
          return "input-radio__correct";
        }
      }

      const questionChoice = lesson.questions
        .find(
          (q) =>
            q.id === id &&
            q.homeworks &&
            typeof (q.homeworks, "array") &&
            q.homeworks.length > 0
        )
        ?.question_choise?.values?.find(
          (c: any) => c.value === value && c.is_correct
        );

      if (questionChoice) {
        return "input-radio__correct";
      }
    }
    return "";
  };

  const UploadPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const formData = new FormData();
      Array.from(event.target.files).forEach((file) => {
        formData.append("files", file, file.name);
      });
      const execregex = /\d+/.exec(event.target.name);
      if (execregex) {
        const question_id = execregex[0];
        axios
          .post(`/api/v1/homework/${question_id}/attachment`, formData, {
            headers: {
              ...authHeader(),
              accept: "application/json",
              "Content-Type": "multipart/form-data", // Set Content-Type to multipart/form-data
            },
          })
          .then(() => {
            setH_attachment({ photofile: event.target.value });
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
          });
      }
    }
  };
  const openAttachmentModal = (attachmentPath: string) => {
    setAttachamentModalActive(true);  
    setAttachmentPath(attachmentPath); 
  };
  const onSubmit = (data: any) => {
    const homeworks = [];
    for (const i in data) {
      console.log(i);
      if (data[i]) {
        homeworks.push({
          question_id: i.split("value")[1],
          value: data[i],
        });
      }
    }
    const totalQuestions = lesson?.questions?.length || 0;
    if (homeworks.length == totalQuestions || homeworks.length + 2 >= totalQuestions) {
      axios
        .post("/api/v1/homework", homeworks, { headers: authHeader() })
        .then((response) => {
          console.log(response.data);
          if (response.status == 200) {
            window.location.reload();
          }
        })
        .catch((error) => {
          alert("ошибка");
          console.log(error.response.data);
        });
    } else {
      console.log(homeworks.length);
      console.log(totalQuestions);
      alert('Нужно ответить на все вопросы');
    }
  };

  let donehomework = false;
  if (lesson.questions) {
    lesson.questions.filter((obj) => {
      if (obj.homeworks) {
        if (obj.homeworks.length > 0) {
          return (donehomework = true);
        }
      }
    });
  }

  function getDeadLineText(lesson: ILesson) {
    if (lesson?.end_date) {
      const endDate = moment(lesson.end_date);
      const deadLine = moment(endDate).add(3, "days");
      const todaysDate = moment();
      const daysDifference = deadLine.diff(todaysDate, "days");
      if (daysDifference < 0) {
        return ``;
      } else if (daysDifference === 0) {
        return "Сегодня последний день";
      } else if (daysDifference > 0 && daysDifference < 4) {
        return `Осталось ${Math.abs(daysDifference)} дн.`;
      } else {
        return ``;
      }
    }
  }

  return (
    <>
      <ul className="breadcrumbs">
        <li>
            <Link to="/my-courses">Мои курсы</Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
            <Link to={
              course && course.price == 10  ? 
              `/course_${course?.id}` :
              course?.price == 20 ?  `/course2_${course?.id}` : `/courseOld_${course?.id}`
            }>
              {course?.name}
            </Link>
        </li>
        <li>
          <span>
            {lesson?.course_id && <i className="fa-solid fa-arrow-right"></i>}
          </span>
        </li>
        <li>
          <span>
            {lesson?.course_id && `Домашнее задание - ${lesson?.name}`}
          </span>
          {getDeadLineText(lesson) && (
            <span className="homework-lesson__deadline">
              {getDeadLineText(lesson)}
            </span>
          )}
        </li>
      </ul>
      <div className="homework box">
        <div className="homework__top">
          <div className="homework__top__left">
            {lesson.lesson_type == "control" ? (
              <span className="homework__heading homework__heading--zachet">{lesson.name}</span>
            ) : (
              <>
                <span className="homework__heading">Домашнее задание:</span>
                <span className="homework__heading homework__heading--zachet"> {lesson.name}</span>
              </>
            )}
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="homework__area">
          <ul className="homework-ul">
            {lesson["questions"] !== undefined ? (
              lesson["questions"]
              .sort((a: any, b: any) => a?.sequence - b?.sequence)
              .map((question, number) => (
                <li key={question.id}>
                  <div className="question-row">
                    <span className="question-row__heading">
                      <span>{number + 1}) </span> {question.display_name}
                      {question.image !== null ? (
                        <div className="question__img">
                          <img src={question.image}></img>
                        </div>
                      ) : (
                        ""
                      )}
                    </span>
                    {question["question_type"] &&
                    question["question_type"] == QuestionType.choice ? (
                      <div className="question-row__area">
                        {question["question_choise"] !== undefined &&
                        question["question_choise"]["values"] !== undefined &&
                        question["question_choise"]["values"].length > 0 ? (
                          question["question_choise"]["values"].map(
                            (choice) => (
                              <div key={choice.id} className="input-row">
                                <input
                                  {...register("value" + question.id)}
                                  value={choice.value}
                                  id={"" + choice.id}
                                  type="radio"
                                  disabled={answered(question.id)}
                                />
                                {choice.image !== null ? (
                                  <label
                                    htmlFor={"" + choice.id}
                                    className={
                                      lesson.homework_visible == true
                                        ? correctAnswer(
                                            question.id,
                                            choice.value
                                          )
                                        : ""
                                    }
                                  >
                                    <div className="admin-choice-row__img">
                                      <img src={choice.image}></img>
                                    </div>
                                  </label>
                                ) : (
                                  <label
                                    htmlFor={"" + choice.id}
                                    className={
                                      lesson.homework_visible == true
                                        ? correctAnswer(
                                            question.id,
                                            choice.value
                                          )
                                        : ""
                                    }
                                  >
                                    {choice.value}
                                  </label>
                                )}
                              </div>
                            )
                          )
                        ) : (
                          <div>пусто</div>
                        )}
                      </div>
                    ) : (
                      <div className="task-area">
                        <div className="task-area__input">
                          <div className="input-student-solution">
                            <label htmlFor={"file" + question.id}>
                              <i className="fa-regular fa-image"></i>
                              {question.homeworks && question.homeworks.length > 0 ? "Обновить решение" : "Загрузить решение"}
                            </label>
                            <input type="file" name={"file" + question.id} id={"file" + question.id} onChange={UploadPhoto} accept="image/png, image/jpeg" multiple />
                          </div>  
                        </div> 
                        {question.homeworks && question.homeworks.length > 0 ? (
                            <>
                              <div className="att-images">
                                {question.homeworks[0].attachments.map((attachment:any,index:number) => (
                                  <div className="att-images__item" key={attachment[index]}>
                                    <img
                                      onClick={() => openAttachmentModal(attachment)}
                                      src={"https://app.easyent.kz" + attachment}
                                      alt="Homework attachment"
                                    />
                                  </div>
                                  )  
                                )}
                              </div>
                              {question.homeworks[0].check_date !== null ? (
                                question.homeworks[0].is_correct ? (
                                  <p className="check-answer"><i className="fa-solid fa-check"></i> верно</p>
                                ) : (
                                  <p className="check-answer check-answer__wrong"><i className="fa-solid fa-xmark"></i> не верно</p>
                                )
                              ) : ""}
                            </>
                          ) : ""}   
                      </div>
                    )}
                  </div>
                </li>
              ))
            ) : (
              <div>Загрузка</div>
            )}
          </ul>
          {!location?.state?.isParent && (
            <div>
              {donehomework ? (
                <div className="homework-done">
                  <div className="homework-done__top">
                    {homeworkPercent ? 
                      (
                        <>
                              {
                                homeworkPercent >= 85  ? (
                                  <div className="homework-grade">
                                    <div className="homework-grade__img">
                                      <img src="/assets/svg/crown.svg" />      
                                    </div>
                                    <div className="homework-grade__text">
                                      <div className="homework-grade__heading legend_grade">Легенда: {corrcetCount} из {lesson.questions ? lesson.questions.length : 0}</div>
                                      
                                    </div>
                                  </div>
                                ) : ""
                              }
                              {
                                homeworkPercent >= 70 &&  homeworkPercent < 85 ? (
                                  <div className="homework-grade">
                                    <div className="homework-grade__img">
                                      <img src="/assets/svg/good_grade.svg" />    
                                    </div>
                                    <div className="homework-grade__text">
                                      <div className="homework-grade__heading good_grade">Красава: {corrcetCount} из {lesson.questions ? lesson.questions.length : 0}</div>
                                      <p><span>{homeworkPercent.toString().substring(0, 2)}%</span> правильных ответов</p>
                                    </div>
                                  </div>                                   
                                ) : ""
                              }
                              {
                                homeworkPercent >= 50 &&  homeworkPercent < 70 ? (
                                  <div className="homework-grade">
                                    <div className="homework-grade__img">
                                      <img src="/assets/svg/norm_grade.svg" />   
                                    </div>
                                    <div className="homework-grade__text">
                                      <div className="homework-grade__heading norm_grade">Норм, но ты можешь лучше: {corrcetCount} из {lesson.questions ? lesson.questions.length : 0}</div>
                                      <p><span>{homeworkPercent.toString().substring(0, 2)}%</span> правильных ответов</p>
                                    </div>
                                  </div> 
                                ) : ""
                              }
                              {
                                homeworkPercent < 50 ? (
                                  <div className="homework-grade">
                                    <div className="homework-grade__img">
                                      <img src="/assets/svg/bad_grade.svg" />
                                    </div>
                                    <div className="homework-grade__text">
                                      <div className="homework-grade__heading bad_grade">Нужно лучше готовиться к уроку: {corrcetCount} из {lesson.questions ? lesson.questions.length : 0}</div>
                                      <p><span>{homeworkPercent.toString().substring(0, 2)}%</span> правильных ответов</p>
                                    </div>
                                  </div> 
                                ) : ""
                              }
                        </>
                      )
                    : ""}
                    <div className="homework-date">
                      <p><span>Дата сдачи дз:</span></p> 
                      <p>{homeworkDate ? homeworkDate.toString().substring(0, 10) : ""}</p>
                    </div>
                  </div>
                </div>) : (
                <>
                  <button
                    onClick={showPopconfirm}
                    className="my-btn"
                    type="button"
                  >
                    Сдать домашнее задание{" "}
                    <i className="fa-solid fa-chevron-right"></i>
                  </button>
                  <Popconfirm
                    visible={visible}
                    header="Вы действительно хотите сдать домашнее задание?"
                    footer={
                      <>
                        <button
                          type="submit"
                          className="popconfirm__btn-primary"
                          style={{ marginRight: 10 }}
                        >
                          Сдать
                        </button>
                        <button
                          type="button"
                          onClick={showPopconfirm}
                          className="popconfirm__btn-secondary"
                        >
                          Закрыть окно
                          <i className="fa-solid fa-xmark"></i>
                        </button>
                      </>
                    }
                  />
                </>
                )
              }
            </div>
          )}
        </form>
        {attachamentModalActive && attachmentPath !== null && (
          <ModalAttachment
            active={attachamentModalActive}
            attachment={attachmentPath} 
            setActive={setAttachamentModalActive}
          />
        )}
      </div>
    </>
  );
};
