import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { AdminUser, UserOrder, createOrder, OrderPosition, orderStatus} from "src/interface";
import { authHeader } from "src/utils/authHeader";
import { ModalCourseAdd, ModalOrderChange} from "src/components";
import "./AdminUserPayments.scss";

export const AdminUserPayments = () => {
  document.title = "Редактирования пользователя | EasyENT";
  const params = useParams();
  const [user, setUser] = useState<AdminUser>();
  const [orderModalActive, setOrderModalActive] = useState(false);
  const [orders, setOrders] = useState<UserOrder[]>([]);
  const [positions, setPositions] = useState<OrderPosition[]>([]);
  const [orderStatus, setOrderStatus] = useState<orderStatus>({
    value:"payed", label: 'Оплачено'
  });
  const orderoptions:orderStatus[] = [
    { value: 'draft', label: 'На оплату' },
    { value: 'payed', label: 'Оплачено' },
    { value: 'canceled', label: 'Отменено' },
  ];
  useEffect(() => {
    axios
      .get("/api/v1/users/" + params.id + "/", { headers: authHeader() })
      .then(
        (response) => (
          setUser(response.data)
        )
      );
  }, []);
  useEffect(() => {
    if(user){
      axios
      .get(`/api/v1/order/?skip=0&limit=100&user_id=${user.id}&status=${orderStatus.value}`, { headers: authHeader() })
      .then((response:any) => setOrders(response.data));
    }
  }, [user, orderStatus]);

  useEffect(() => {
    setOrder((prev) => ({ ...prev, positions: positions }));
  }, [positions]);


  const [order, setOrder] = useState<createOrder>({
    start_date: "",
    user_id: 0,
    end_date: "",
    total_amount: 0,
    is_full: false,
    package: "standard",
    created_at: "",
    status: "payed",
    positions: [],
  });
  useEffect(() => {
    if(user){
      setOrder((prev) => ({ ...prev, user_id: user.id }));
    }
  }, [user]);

  const [orderUpdateModalActive, setOrderUpdateModalActive] = useState(false); 
  const [orderNew, setOrderNew] = useState<UserOrder | null>();

  const openUpdateOrderModal = (orderNew:any) => {
    setOrderNew(orderNew);
    setOrderUpdateModalActive(true);           
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const value = element.value;
    const name = element.name;
    if (name === "created_at") {
      const isoDate = new Date(value).toISOString(); // Convert to ISO format
      setOrder((prev) => ({ ...prev, [name]: isoDate }));
    } else {
      setOrder((prev) => ({ ...prev, [name]: value }));
    }
    
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      order.total_amount !== 0 &&
      order.positions.length > 0  
    ) {
      axios
        .post("/api/v1/order/create_raw", order, { headers: authHeader() })
        .then((response) => {
          if (response.status == 200) {
            alert("Платеж создан");
          }
        })
        .catch((error) => {
          alert(error.response.data);
        });
    } else {
      alert("заполните все данные");
    }
  };
  return (
    <div>
      <h1 className="title">Платежи: {user?.full_name}</h1>
      {user ? (
        <div>
          <div className="box admin-top">
            <form onSubmit={onSubmit}>
              <div className="les-row">
                <label className="les-row__name">Cумма:</label>
                <input onChange={onChange} name="total_amount" className="les-row__input" type="text"></input>
              </div>  
              <div className="les-row">
                <label className="les-row__name">Период:</label>
                <div className="flex">
                  <div className="date-row">
                    <input onChange={onChange} name="start_date" type="date"></input>
                  </div>
                  <div className="date-row">
                    <input onChange={onChange} name="end_date" type="date"></input>
                  </div>
                </div>
              </div>
              <div className="les-row">
                <label className="les-row__name">Пакет:</label>
                <label className="les-radio">
                  <input
                    type="radio"
                    name="package"
                    checked={order.package === "standard"}
                    value="standard"
                    onChange={() => setOrder((prev) => prev && { ...prev, package: "standard" })}
                  />
                  Про
                </label>
                <label className="les-radio">
                  <input
                    type="radio"
                    name="package"
                    checked={order.package === "premium"}
                    value="premium"
                    onChange={() => setOrder((prev) => prev && { ...prev, package: "premium" })}
                  />
                  Премиум
                </label>
              </div>
              <div className="les-row">
                <label className="les-row__name">Тип оплаты:</label>
                <label className="les-radio">
                  <input
                    type="radio"
                    name="is_full"
                    checked={!order.is_full}
                    value="false"
                    onChange={() => setOrder((prev) => prev && { ...prev, is_full: false })}
                  />
                  Месяц
                </label>
                <label className="les-radio">
                  <input
                    type="radio"
                    name="is_full"
                    checked={order.is_full}
                    value="true"
                    onChange={() => setOrder((prev) => prev && { ...prev, is_full: true })}
                  />
                  Год
                </label>
              </div>
              <div className="les-row">
                <label className="les-row__name">Курсы:</label>
                <div>
                  <ul className="ocourse-ul">  
                  {positions.length ?  positions.map((position) => (
                    <li>{position.course_id}</li>
                    )) : ""
                  }
                  </ul>
                  <div onClick={order.start_date !== "" && order.end_date !== "" ?  () => setOrderModalActive(true) : () => alert("Заполните")} className="btn btn-grey">Добавить курс <i className="fa-solid fa-plus"></i></div>
                </div>
              </div>
              <div className="les-row">
                <label className="les-row__name">Дата оплаты:</label>
                <div className="date-row">
                  <input onChange={onChange} name="created_at" type="date"
                  value={order.created_at ? order.created_at.split("T")[0] : ""}
                  ></input>
                </div>
              </div>    
              <button className="btn btn-primary" type="submit">
                Создать платежку
              </button>
            </form>
          </div>
          <div className="box status-top">
            <div className="les-row les-row--nobot">
               <label className="les-row__name">Статус оплаты:</label>
               <Select
                value={orderStatus}
                options={orderoptions}
                onChange={(option: any) => {
                  if (option) setOrderStatus(option)
                }} 
              />    
            </div>
          </div>
          <ul className="user-payements-list">
            {orders.length ?  orders.map((order) => (
              <li key={order.id}>
                <div className="admin-pay-item">
                  <div className="admin-pay-item__top">
                    <span className="admin-pay-item__heading">ID: {order.id}</span>
                    <span className="admin-pay-item__date">
                      {order.start_date
                        ? new Intl.DateTimeFormat('ru-RU', { month: 'long' }).format(new Date(order.start_date))
                        : 'Нет даты'}
                    </span>
                  </div>
                  <div className="pay-row">
                    <span>Период оплаты:</span> {order.start_date} - {order.end_date} 
                  </div>
                  <div className="pay-row">
                    <span>Пакет:</span> {order.package == 'standard' ? 'Про' : 'Премиум'}
                  </div>
                  <div className="pay-row">
                    <span>Тип оплаты:</span> {order.is_full ? 'Год' : 'Месяц'}                   
                  </div>
                  <div className="pay-row">
                    <span>Цена:</span> {order.total_amount} 
                  </div>
                  <div className="pay-row">
                    <span>Дата оплаты:</span> {order.created_at}
                  </div>
                  <div className="pay-row pay-row--last">
                    <span>Статус:</span> {order.status == 'payed' ? 'Оплачено' : 'На оплату'}
                  </div>
                  <button onClick={() => openUpdateOrderModal(order)} className="btn btn-blue">Изменить</button>
                </div>
              </li>
            ))  : "Загрузка" }  
          </ul> 
          {orderModalActive !== null && (
            <ModalCourseAdd
              active={orderModalActive}
              start_date={order.start_date ? order.start_date : ""}
              end_date={order.end_date ? order.end_date : ""}
              setActive={setOrderModalActive}
              positions={positions}
              setPositions={setPositions}
            />
          )}
          {orderUpdateModalActive && orderNew !== null && (
            <ModalOrderChange
              active={orderUpdateModalActive}
              orderId={orderNew?.id ? orderNew?.id : 1}
              orderUsername={user?.full_name ? user?.full_name : "Ошибка"}
              userPricelist={user?.pricelist ? user?.pricelist : 1}
              setActive={setOrderUpdateModalActive}
            />
          )}    
        </div>
      ) : (
        <p>Загрузка</p>
      )}
    </div>
  );
};
